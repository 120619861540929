import PropTypes from 'prop-types';

import { Container } from './styles';

const InputDate = ({
	name, placeholder, value, validator, handleChange,
}) => (
	<Container>
		<input
			className={ `${value && 'filled-input'}` }
			style={ { width: `${validator.maxLength === 4 ? '27px' : '18px'}` } }
			type="tel"
			name={ name }
			value={ value }
			pattern={ validator.pattern }
			minLength={ validator.minLength }
			maxLength={ validator.maxLength }
			onChange={ (e) => { handleChange(e.currentTarget); } }
			required
		/>
		<label htmlFor={ name }>{ placeholder }</label>
	</Container>
);

InputDate.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	validator: PropTypes.shape({
		pattern: PropTypes.string.isRequired,
		minLength: PropTypes.number.isRequired,
		maxLength: PropTypes.number.isRequired,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default InputDate;