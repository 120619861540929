import { THIRTY_DAY_MONTHS } from './constants';

const validateLeapYear = (year) => {
	const yearValue = parseInt(year, 10);
	if (yearValue % 4 === 0) { return true; }
	return false;
};

const validateFebruary = ({ day, month, year }) => {
	if (month !== '02') { return true; }
	if (day === '30' || day === '31') { return false; }
	if (day === '29' && !validateLeapYear(year)) { return false; }
	return true;
};

const validate30DayMonths = ({ day, month }) => {
	if (day === '31' && THIRTY_DAY_MONTHS.some((monthValue) => monthValue === month)) {
		return false;
	}
	return true;
};

export default function validateDate(date) {
	return (validateFebruary(date) && validate30DayMonths(date));
}