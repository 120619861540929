import { Container } from './styles';

import logo from '../../assets/images/logo.svg';

const Header = () => (
	<Container>
		<img src={ logo } alt="Logo" />
	</Container>
);

export default Header;