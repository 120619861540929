export default {
	colors: {
		background: '#F6F5FC',
		primary: {
			lighter: '#E0E3FF',
			light: '#6674F4',
			main: '#5061FC',
			dark: '#3346F0',
		},
		danger: {
			light: '#F97171',
			main: '#FC5050',
			dark: '#F63131',
		},
		grey: {
			light: '#E5E5E5',
			main: '#BCBCBC',
			dark: '#222',
		},
	},

	shadows: {
		primary: {
			light: '0px 4px 10px rgba(24, 94, 224, 0.15)',
			main: '0px 4px 10px rgba(24, 94, 224, 0.3)',
		},
		danger: {
			main: '0px 4px 10px rgba(235, 47, 100, 0.15)',
		},
		grey: {
			light: '0px 4px 10px rgba(0, 0, 0, 0.04)',
			main: '0px 4px 10px rgba(0, 0, 0, 0.2)',
			dark: '0px 4px 10px rgba(0, 0, 0, 0.5)',
		},
	},
};