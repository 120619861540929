import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 300px;

	input {
		width: 90%;
		margin: 0 auto;
		box-shadow: ${({ theme }) => theme.shadows.grey.main};
		border: solid 1px ${({ theme }) => theme.colors.grey.light};
		border-radius: .4rem;
		background: none;
		padding: .8rem;
		font-size: 1rem;
		color: ${({ theme }) => theme.colors.grey.dark};
		transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
	}

	label {
		position: absolute;
		top: -.15rem;
		left: 1.5rem;
		color: ${({ theme }) => theme.colors.grey.dark};
		pointer-events: none;
		transform: translateY(1rem);
		transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
	}


	input:focus, filled-input {
		outline: none;
		color: ${({ theme }) => theme.colors.danger.light};
		box-shadow: ${({ theme }) => theme.shadows.danger.main};
		border: 1px solid ${({ theme }) => theme.colors.danger.main};
	}

	input:valid {
		outline: none;
		color: ${({ theme }) => theme.colors.grey.dark};
		box-shadow: ${({ theme }) => theme.shadows.primary.light};
		border: 1px solid ${({ theme }) => theme.colors.primary.main};
	}

	input:focus ~ label, input:valid ~ label, .filled-input ~ label {
		transform: translateY(-50%) translateX(-20%) scale(0.8);
		background-color: #FFF;
		padding: 0 .2em;
		color: ${({ theme }) => theme.colors.danger.main};
	}

	input:valid ~ label {
		color: ${({ theme }) => theme.colors.primary.main};
	}

	input:disabled {
		outline: none;
		color: ${({ theme }) => theme.colors.grey.light};
		border: 1px solid ${({ theme }) => theme.colors.grey.dark};
		box-shadow: ${({ theme }) => theme.shadows.grey.main};
	}

	input:disabled ~ label {
		color: ${({ theme }) => theme.colors.grey.dark};
	}
`;