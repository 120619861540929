import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    padding: .2rem;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.grey.dark};

	label {
		font-size: .9rem;
		position: absolute;
		top: -.6rem;
		left: .4rem;
		color: ${({ theme }) => theme.colors.grey.dark};
		pointer-events: none;
		transform: translateY(1rem);
		transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
	}

	input{
		width: 90%;
		margin: 0 auto;
		box-shadow: ${({ theme }) => theme.shadows.grey.main};
		border: solid 1px ${({ theme }) => theme.colors.grey.main};
		border-radius: .2rem;
		background: none;
		padding: .1rem 0 .1rem .2rem;
		font-size: 1rem;
		color: ${({ theme }) => theme.colors.grey.dark};
		transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
	}

	input:focus, .filled-input {
		outline: none;
		color: ${({ theme }) => theme.colors.danger.light};
		box-shadow: ${({ theme }) => theme.shadows.danger.main};
		border: 1px solid ${({ theme }) => theme.colors.danger.main};
	}

	input:valid {
		outline: none;
		color: ${({ theme }) => theme.colors.grey.dark};
		box-shadow: ${({ theme }) => theme.shadows.primary.light};
		border: 1px solid ${({ theme }) => theme.colors.primary.main};
	}

	input:focus ~ label, input:valid ~ label, .filled-input ~ label {
		transform: scale(0);
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type=number]{
		-moz-appearance: textfield;
	}
`;