export const NAME_VALIDATOR = {
	pattern: '^[a-zA-záàâãéèêíïóôõöúçÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇ ]+$',
	minLength: 3,
	maxLength: 80,
};
export const DAY_VALIDATOR = {
	pattern: '^(?:0[1-9]|[1-2][0-9]|3[0-1])$',
	minLength: 2,
	maxLength: 2,
};
export const MONTH_VALIDATOR = {
	pattern: '^(?:0[0-9]|1[0-2])$',
	minLength: 2,
	maxLength: 2,
};
export const YEAR_VALIDATOR = {
	pattern: '^(?:20[0-2][0-9]|19[0-9]{2})$',
	minLength: 4,
	maxLength: 4,
};
export const THIRTY_DAY_MONTHS = ['04', '06', '09', '11'];