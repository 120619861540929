import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import OduCircle from '../../components/OduCircle';
import calcOdus from '../../utils/calcOdus';
import { Container } from './style';

const Result = () => {
	const { state } = useLocation();
	const {
		name, day, month, year,
	} = state;

	const [odus, setOdus] = useState();

	useEffect(() => {
		if (state) { setOdus(calcOdus({ day, month, year })); }
	}, [day, month, state, year]);

	return (
		<Container>
			<span>{ name }</span>
			<span>{ `${day}/${month}/${year}` }</span>
			{ odus
			&& <OduCircle odus={ odus } />}
		</Container>
	);
};

export default Result;