import PropTypes from 'prop-types';
import { Container } from './style';

const Button = ({ children, disabled, onClick }) => (
	<Container>
		<button type="button" onClick={ onClick } disabled={ disabled }>
			{ children }
		</button>
	</Container>
);

Button.propTypes = {
	children: PropTypes.node.isRequired,
	disabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
	disabled: false,
};

export default Button;