import PropTypes from 'prop-types';

import { Container } from './style';

const Input = ({
	name, placeholder, value, validator, handleChange,
}) => (
	<Container>
		<input
			className={ value && 'filled-input' }
			type="text"
			name={ name }
			value={ value }
			pattern={ validator.pattern }
			minLength={ validator.minLength }
			maxLength={ validator.maxLength }
			onChange={ (e) => { handleChange(e.currentTarget.value); } }
			required
		/>
		<label htmlFor={ name }>{ placeholder }</label>
	</Container>
);

Input.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	validator: PropTypes.shape({
		pattern: PropTypes.string.isRequired,
		minLength: PropTypes.number.isRequired,
		maxLength: PropTypes.number.isRequired,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default Input;