import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    position: relative;

    margin-top: 5rem;

	span {
		position: absolute;

		font-size: 1.6rem;
		font-weight: 600;
		color: ${({ theme }) => theme.colors.grey.dark}
	}

	.center {
		top: 43%;
		left: 52%;
	}

	.top {
		top: -30px;
		left: 52%;
	}

	.bottom {
		top: 260px;
		left: 52%;
	}

	.left {
		top: 43%;
		left: -30px;
	}

	.right {
		top: 43%;
		left: 260px;
	}
`;

export const Circle = styled.div`
    width: 250px;
    height: 250px;

    border: 4px solid ${({ theme }) => theme.colors.grey.dark};
    border-radius: 50%;
`;

export const VerticalLine = styled.div`
    position: absolute;
    top: -30px;
    left: 50%;
    height: 310px;

    border-left: 4px solid ${({ theme }) => theme.colors.grey.dark};
`;

export const HorizontalLine = styled.div`
    position: absolute;
    top: 50%;
    left: -30px;
    width: 310px;

    border-top: 4px solid ${({ theme }) => theme.colors.grey.dark};
`;