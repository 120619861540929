import PropTypes from 'prop-types';

import { Container } from './styles';
import { DAY_VALIDATOR, MONTH_VALIDATOR, YEAR_VALIDATOR } from '../../utils/constants';

import InputDate from '../InputDate';

const DateForm = ({ date, setDate }) => {
	const handleDayChange = (target) => {
		const { value } = target;
		if (value.length === DAY_VALIDATOR.maxLength) {
			const monthInput = document.querySelector('input[name=month]');
			monthInput?.focus();
		}
		setDate((prevState) => ({ ...prevState, day: value }));
	};

	const handleMonthChange = (target) => {
		const { value } = target;
		if (value.length === MONTH_VALIDATOR.maxLength) {
			const yearInput = document.querySelector('input[name=year]');
			yearInput?.focus();
		}
		setDate((prevState) => ({ ...prevState, month: value }));
	};

	const handleYearChange = (target) => {
		setDate((prevState) => ({ ...prevState, year: target.value }));
	};

	return (
		<Container>
			Data de Nascimento:&nbsp;
			<InputDate
				name="day"
				placeholder="DD"
				value={ date.day }
				validator={ DAY_VALIDATOR }
				handleChange={ handleDayChange }
			/>
			/
			<InputDate
				name="month"
				placeholder="MM"
				value={ date.month }
				validator={ MONTH_VALIDATOR }
				handleChange={ handleMonthChange }
			/>
			/
			<InputDate
				name="year"
				placeholder="AAAA"
				value={ date.year }
				validator={ YEAR_VALIDATOR }
				handleChange={ handleYearChange }
			/>
		</Container>
	);
};

DateForm.propTypes = {
	date: PropTypes.shape({
		day: PropTypes.string.isRequired,
		month: PropTypes.string.isRequired,
		year: PropTypes.string.isRequired,
	}).isRequired,
	setDate: PropTypes.func.isRequired,
};

export default DateForm;