import styled from 'styled-components';

export const Container = styled.div`
	padding: 2rem;

	button {
		width: 200px;
		height: 200px;

		padding: .5rem 1rem;
		background-color: ${({ theme }) => theme.colors.primary.light};
		border: solid 2px ${({ theme }) => theme.colors.primary.main};
		border-radius: 50%;
		box-shadow: ${({ theme }) => theme.shadows.primary.main};

		font-size: 1.2rem;
		color: ${({ theme }) => theme.colors.primary.main};

		cursor: pointer;

		&:active {
			transform: translateY(0.1rem);
			box-shadow: inset 0 .5rem 1rem rgba(0, 0, 0, 0.2);
		}

		&:disabled {
			transform: translateY(0);

			background-color: ${({ theme }) => theme.colors.danger.light};
			border: 2px solid ${({ theme }) => theme.colors.danger.main};
			box-shadow: ${({ theme }) => theme.shadows.danger.main};

			color: ${({ theme }) => theme.colors.danger.dark};

			cursor: default;
		}
	}
`;