import styled from 'styled-components';

export const Container = styled.div`
	padding: 1rem;

	display: flex;
	align-items: center;
	justify-content: space-around;

	font-size: 1rem;
`;