import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Container } from './assets/styles/App';

import GlobalStyles from './assets/styles/global';
import defaultTheme from './assets/styles/themes/default';

import Router from './router';
import Header from './components/Header';

const App = () => (
	<BrowserRouter>
		<ThemeProvider theme={ defaultTheme }>
			<GlobalStyles />
			<Container>
				<Header />
				<Router />
			</Container>
		</ThemeProvider>
	</BrowserRouter>
);

export default App;