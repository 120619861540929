import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: -5rem;

	&> span {
		padding: 2rem;
		font-size: 1.6rem;
	}
`;