import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	NAME_VALIDATOR,
	DAY_VALIDATOR,
	MONTH_VALIDATOR,
	YEAR_VALIDATOR,
} from '../../utils/constants';
import validateDate from '../../utils/validateDate';
import validateInput from '../../utils/validateInput';
import Input from '../../components/Input';
import Button from '../../components/Button';
import DateForm from '../../components/DateForm';
import { Container } from './styles';

const Home = () => {
	const [name, setName] = useState('');
	const [date, setDate] = useState({ day: '', month: '', year: '' });

	const navigate = useNavigate();

	const handleClick = () => {
		if (!validateDate(date)) {
			// eslint-disable-next-line no-alert
			alert('Data de nascimento inválida!');
			return;
		}
		navigate('/result', { state: { name, ...date } });
	};

	const canConfirm = () => {
		if (!validateInput(name, NAME_VALIDATOR)) return false;
		if (!validateInput(date.day, DAY_VALIDATOR)) return false;
		if (!validateInput(date.month, MONTH_VALIDATOR)) return false;
		if (!validateInput(date.year, YEAR_VALIDATOR)) return false;
		return true;
	};

	return (
		<Container>
			<Input
				name="name"
				placeholder="Nome"
				value={ name }
				validator={ NAME_VALIDATOR }
				handleChange={ setName }
			/>
			<DateForm date={ date } setDate={ setDate } />
			<Button onClick={ handleClick } disabled={ !canConfirm() }>
				Calcular Odus
			</Button>
		</Container>
	);
};

export default Home;