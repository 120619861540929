import PropTypes from 'prop-types';

import {
	Container, Circle, VerticalLine, HorizontalLine,
} from './styles';

const OduCircle = ({ odus }) => (
	<Container>
		<Circle />
		<VerticalLine />
		<HorizontalLine />
		<span className="center">{ odus.center }</span>
		<span className="top">{ odus.top }</span>
		<span className="right">{ odus.right }</span>
		<span className="bottom">{ odus.bottom }</span>
		<span className="left">{ odus.left }</span>
	</Container>
);

OduCircle.propTypes = {
	odus: PropTypes.shape({
		birth: PropTypes.number.isRequired,
		center: PropTypes.number.isRequired,
		top: PropTypes.number.isRequired,
		bottom: PropTypes.number.isRequired,
		left: PropTypes.number.isRequired,
		right: PropTypes.number.isRequired,
	}).isRequired,
};

export default OduCircle;