const parseDecimal = (value) => parseInt(value, 10);

const reduceToSixteen = (calculatedOdu) => {
	if (calculatedOdu > 16) {
		return parseDecimal(calculatedOdu.toString()[0]) + parseDecimal(calculatedOdu.toString()[1]);
	}
	return calculatedOdu;
};

const calcTop = (day, month, year) => {
	const top = parseDecimal(day[1])
		+ parseDecimal(month[1])
		+ parseDecimal(year[1])
		+ parseDecimal(year[3]);
	return reduceToSixteen(top);
};

const calcBottom = (day, month, year) => {
	const bottom = parseDecimal(day[0])
		+ parseDecimal(month[0])
		+ parseDecimal(year[0])
		+ parseDecimal(year[2]);
	return reduceToSixteen(bottom);
};

const calcRight = (top, bottom) => {
	const right = top + bottom;
	return reduceToSixteen(right);
};

const calcLeft = (top, bottom, right) => {
	const left = top + bottom + right;
	return reduceToSixteen(left);
};

const calcCenter = (top, bottom, right, left) => reduceToSixteen(top + bottom + right + left);

const calcBirth = (day, month, year) => {
	const center = parseDecimal(day[0])
	+ parseDecimal(day[1])
	+ parseDecimal(month[0])
	+ parseDecimal(month[1])
	+ parseDecimal(year[0])
	+ parseDecimal(year[1])
	+ parseDecimal(year[2])
	+ parseDecimal(year[3]);
	return reduceToSixteen(center);
};

export default function calcOdus({ day, month, year }) {
	const top = calcTop(day, month, year);
	const bottom = calcBottom(day, month, year);
	const right = calcRight(top, bottom);
	const left = calcLeft(top, bottom, right);
	const center = calcCenter(top, bottom, right, left);
	const birth = calcBirth(day, month, year);
	return {
		birth,
		center,
		top,
		bottom,
		left,
		right,
	};
}